@import 'libs/wallex-core/src/styles/variables';

body {
  .p-dialog-mask.p-component-overlay {
    z-index: 2147483002 !important;
  }

  .p-dialog .p-dialog-content {
    padding: 1px 24px 24px;

    color: var(--main-text);
    border-top: 0 none;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background: var(--item-background);

    @include text(var(--font-base-regular), $font-size-small, 22px, 400);
  }

  .p-dialog .p-dialog-footer {
    background: var(--item-background);
  }

  .p-dialog .p-dialog-header {
    padding: 24px;

    color: var(--main-text);
    border-bottom: 0 none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: var(--item-background);

    .p-dialog-title {
      word-break: break-all;

      @include text(var(--font-base-medium), $font-size-large, 28px, 500);
    }
  }

  .no-x-button .p-dialog-content {
    min-width: 300px;
    padding: 1px 24px 24px;

    color: var(--main-text);
    border-top: 0 none;
  }

  .no-x-button.bigger-padding .p-dialog-header {
    padding-bottom: 0;
  }

  .no-x-button.bigger-padding .p-dialog-content {
    padding: 24px;
  }

  .no-x-button .p-dialog-header {
    min-width: 300px;
    padding: 24px 24px 8px;

    border-bottom: 0 none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .p-dialog-header-icons {
      display: none;
    }
  }

  .p-dialog-header {
    transform: translate(0, 1px);
  }

  .page-dialog {
    .p-dialog-header-icons {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    .p-dialog-header {
      height: 44px;
      padding: 0;
    }
  }

  .base-popup {
    .p-dialog-header {
      height: 44px;
      padding: 0;
    }
  }

  .circle-close {
    position: absolute;
    top: calc(50% - 50vh + 46px);
    right: calc(50% - 50vw + 60px);
  }
}

.popup-dialog {
  &__title {
    margin-bottom: 0;

    color: var(--main-text);

    @include text(var(--font-base-medium), $font-size-large, 28px, 500);
  }

  .p-dialog-header-icons {
    display: none !important;
  }

  .p-dialog-header {
    padding-bottom: 8px;
  }

  .p-dialog-content {
    border-radius: 0 !important;
  }

  .p-dialog-footer {
    padding-right: 16px;

    border-radius: 0 0 $border-radius $border-radius;
  }

  .p-button.p-button-text {
    color: var(--primary-text);
  }
}

iframe {
  border: none;
}

a:hover {
  color: var(--primary-text);
}

@media (max-width: $table-breakpoint) {
  .page-dialog,
  .large-dialog {
    width: 100% !important;
    max-width: 600px;
  }

  body {
    .circle-close {
      top: calc(50% - 50vh + 20px);
      right: calc(50% - 50vw + 20px);
    }
  }
}

@media (max-width: $mobile-breakpoint) {
  .p-dialog {
    width: calc(100% - 20px) !important;
  }

  body {
    .no-x-button .p-dialog-content,
    .no-x-button .p-dialog-header {
      min-width: 300px;
    }

    .p-dialog .p-dialog-content {
      padding: 1px 15px 15px;
    }

    .p-dialog .p-dialog-header,
    .no-x-button.bigger-padding .p-dialog-content {
      padding: 15px;
    }

    .no-x-button .p-dialog-header {
      padding: 15px 15px 4px;
    }

    .circle-close {
      display: none;
    }
  }
}
