.service-wallets {
  overflow: auto;

  max-height: 370px;
}

.service-search-input {
  margin: 12px 0 24px;
}

.service-no-results {
  text-align: center;

  @include text(var(--font-base-regular), $font-size-regular, 24px, 400);
}
