.p-radiobutton .p-radiobutton-box:not(.p-disabled, .p-highlight):hover {
  border-color: var(--primary-text);
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  border-color: var(--item-border);
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: -1px;
  box-shadow: none;
}

.p-radiobutton .p-radiobutton-box {
  border: 1px solid var(--item-border);
  outline-color: transparent;
  background: var(--body-background);
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: var(--primary-text);
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--primary-text);
  background: var(--body-background);
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  color: var(--primary-text);
  border-color: var(--primary-text);
  background: var(--body-background);
}

.p-radiobutton.p-variant-filled .p-radiobutton-box {
  background-color: var(--body-background);
}

.p-radiobutton.p-variant-filled .p-radiobutton-box:not(.p-disabled):hover {
  background-color: var(--body-background);
}

.p-radiobutton.p-variant-filled .p-radiobutton-box.p-highlight {
  background: var(--body-background);
}

.p-radiobutton.p-variant-filled .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: var(--body-background);
}
