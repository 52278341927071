@import 'libs/wallex-core/src/styles/variables';
@import 'libs/wallex-core/src/styles/mixins';

.p-button {
  height: 44px;
  padding: 0 20px;

  color: var(--base-one);
  border-color: var(--button-primary);

  border-radius: $border-radius;
  background: var(--button-primary);

  @include text(var(--font-base-regular), $font-size-regular, 24px, 500);
}

.p-button:enabled:hover {
  color: var(--base-one);
  border-color: var(--hover-primary);
  background: var(--hover-primary);
}

.cancel-button {
  margin-right: 4px;

  color: var(--button-primary);
  border: none;
  background-color: var(--base-one);
}

.semi-button {
  height: 44px;

  color: var(--button-primary);
  border-radius: $border-radius-small;
  background-color: transparent;
}

.button-sm {
  height: 44px;

  border-radius: $border-radius-small;
}

.button-lg {
  height: 56px;

  border-radius: $border-radius;
}

.button--mobile {
  @include small-btn;
}

.grey-button {
  padding: 5px 13px;

  transition: 0.2s ease-in color, 0.2s ease-in background;

  color: var(--secondary-text);
  border-radius: $border-radius-sm;
  background: var(--body-background);

  &.active {
    color: var(--main-text);
    background: var(--item-secondary-background);
  }

  @include text(var(--font-base-regular), $font-size-small, 22px, 400);
}

.cancel-button-regular {
  height: 48px;
  margin-right: 4px;

  color: var(--button-primary);
  border: none;
  background-color: var(--base-one);

  font-size: $font-size-regular;
}

.disabled-button {
  cursor: default;

  opacity: 1;
  border: none;

  font-size: $font-size-regular;
  font-weight: normal;
}

.submit-button {
  height: 56px;
}

.submit-button-regular {
  height: 48px;

  font-size: $font-size-regular;
}

.danger-button {
  border-color: var(--main-red);
  background: var(--main-red);

  &.p-button:hover {
    border-color: var(--main-red);
    background: var(--main-red);
  }
}

button:disabled {
  cursor: no-drop;
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  color: var(--main-text);
  border: none;
  background: var(--grey);
}

.action-icon {
  width: 40px;

  height: 40px;

  margin: 0;
  padding: 0;

  border: none;
  border-radius: 50%;
  background: var(--grey);
  box-shadow: none;

  img {
    transition: 0.1s ease-in filter;
  }

  &:hover img {
    fill: var(--primary-text);
  }

  @include flex(center, center);
}

.light-button {
  height: 40px;
  padding: 9px 16px;

  &,
  &.p-button:hover {
    color: var(--main-text);
    border: 1px solid var(--item-border);
    border-radius: $border-radius-small;
    background: var(--body-background);
  }

  &.p-button.active {
    color: var(--base-one);
    border: 1px solid var(--button-primary);
    background: var(--button-primary);
  }

  @include text(var(--font-base-regular), $font-size-small, 22px, 400);
}

.copy-button {
  display: inline-block;

  width: 24px;
  height: 24px;
  margin-left: 8px;

  cursor: pointer;

  background-image: url('/assets/buttons/copy.svg');
}

.copy-button:hover,
.small-copy-button:hover,
p-password .disabled-key .pi:hover {
  filter: var(--image-hover-filter);
}

.small-copy-button {
  position: absolute;
  top: 17px;
  right: 17px;

  width: 20px;
  height: 20px;

  cursor: pointer;

  background-image: url('/assets/buttons/copy-small.svg');
}
