@import 'libs/wallex-core/src/styles/variables';
@import 'libs/wallex-core/src/styles/mixins';

.p-treetable {
  .p-sortable-column:not(.p-highlight):hover {
    color: var(--secondary-text);
    background: var(--item-background);
  }

  .p-sortable-column.p-highlight {
    color: var(--secondary-text);
    background: var(--item-background);
  }

  .p-treetable-scrollable-header-box {
    padding: 0 !important;
  }

  .p-treetable-thead > tr > th {
    padding: 0 12px 12px;

    color: var(--secondary-text);
    border-bottom: 1px solid var(--section-border);
    background: var(--body-background);

    @include text(var(--font-base-regular), $font-size-small, 22px, 400);
  }

  .p-treetable-tbody > tr > td {
    padding: 16px 12px;

    border-color: var(--item-border);
    background: var(--body-background);
  }

  p-treetabletoggler {
    .pi-chevron-right {
      transform: rotate(90deg);
    }

    .pi-chevron-down {
      transform: rotate(180deg);
    }

    @include flex(center, flex-end);
  }

  .p-treetable-tbody > tr > td .p-treetable-toggler {
    margin: 0;
  }
}

.custom-table {
  width: 100%;

  th {
    padding: 0 0 12px 12px;

    border-bottom: 1px solid var(--item-border);

    @include text(var(--font-base-regular), $font-size-small, 22px, 400);
  }

  td {
    padding: 16px 0 16px 12px;

    border-bottom: 1px solid var(--item-border);
  }

  &:not(.no-padding) tr:last-child td {
    padding: 16px 12px;
  }
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  .p-menuitem-link {
    background: none;
  }

  &:hover {
    background: none;

    .p-menuitem-link {
      background: none !important;
    }
  }
}

.p-tabview {
  .p-tabview-nav {
    overflow-x: auto;
    overflow-y: hidden;

    border-width: 0 0 1px;
    border-color: var(--item-border);
    background: none;
    scroll-behavior: smooth;
    overscroll-behavior: contain auto;
  }

  .p-tabview-nav::-webkit-scrollbar {
    display: none;
  }

  .p-tabview-nav::-webkit-scrollbar-thumb {
    opacity: 0.4;
    border-radius: $border-radius;
    background: var(--scrollbar-color);
  }

  .p-tabview-nav li {
    .p-tabview-nav-link {
      color: var(--secondary-text);
      border-width: 0;

      font-family: var(--font-base-regular);
      font-weight: 400;
    }

    &.p-highlight .p-tabview-nav-link {
      border-width: 0 0 3px;
    }

    &:hover .p-tabview-nav-link,
    & .p-tabview-nav-link {
      background: none !important;
    }
  }

  .p-tabview-nav-content {
    li {
      margin-right: 24px;
    }

    a {
      padding: 0 0 16px !important;

      text-decoration: none;
    }

    .p-highlight a {
      color: var(--primary-text) !important;
      border-color: var(--primary-text) !important;
    }
  }

  .p-tabview-panels {
    padding: 24px 0 0;

    color: var(--secondary-text);
    background: transparent;
  }

  @media only screen and (min-width: $table-xmd-breakpoint) {
    .p-tabview-nav::-webkit-scrollbar {
      display: block;

      width: 6px;
      height: 6px;

      background-color: var(--body-background);
    }
  }
}

.p-datatable {
  .p-datatable-tbody > tr > td {
    padding: 16px 12px;

    border: none;
  }
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0 12px 12px;

  border: none;
}

.p-datatable .p-datatable-tbody > tr,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead {
  border-bottom: 1px solid var(--item-border);
  background: var(--body-background);
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column:not(.p-highlight):hover,
.p-datatable .p-sortable-column.p-highlight:hover {
  color: var(--secondary-text);
  background: transparent;

  @include text(var(--font-base-regular), $font-size-small, 22px, 400);
}

.p-datatable-scrollable .p-datatable-wrapper {
  &::-webkit-scrollbar {
    width: 0;
    height: 6px;

    background-color: var(--body-background);
  }

  &::-webkit-scrollbar-thumb {
    opacity: 0.4;
    border-radius: $border-radius;
    background: var(--scrollbar-color);
  }
}

@media (max-width: $mobile-md-breakpoint) {
  .p-datatable .p-datatable-thead > tr > th {
    font-size: $font-size-xsmall;
  }

  .p-datatable {
    min-width: 320px;
  }
}
