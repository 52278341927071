@import 'libs/wallex-core/src/styles/variables';

.send-container {
  .p-input-icon-right {
    .p-inputtext,
    .p-inputnumber {
      width: 100%;
    }
  }

  .p-inputtext {
    color: var(--main-text);
  }

  .p-button {
    width: 100%;
    height: 56px;
  }
}

.exchange-container {
  .p-button {
    width: 172px;
    height: 56px;
  }
}

.p-virtualscroller-item {
  height: auto !important;
}

.account-content {
  .p-component {
    font-size: $font-size-small;
  }
}

.account-select {
  .p-inputtext {
    padding: 0 16px;

    line-height: 56px;
  }

  input.p-inputtext {
    line-height: 24px;
  }

  .p-dropdown-filter-container .p-inputtext {
    padding: 8px;
  }

  .p-dropdown-panel {
    margin-top: 8px;

    border-radius: $border-radius;

    .p-dropdown-items .p-element .p-dropdown-item {
      margin: 0 6px 0 16px;
      padding: 0;

      border-bottom: 1px solid var(--item-border);

      line-height: 56px;
    }

    .p-dropdown-header {
      border-radius: $border-radius $border-radius 0 0;
    }
  }
}

.form-absolute {
  .account-select {
    .p-inputtext {
      padding: 0;
    }

    .p-dropdown-panel {
      margin-top: -1px;
    }
  }

  .account-select .p-dropdown-panel .p-dropdown-items .p-element:last-child .p-dropdown-item {
    border-bottom: none;
  }
}

.transaction-container {
  .transaction-content {
    margin-bottom: 40px;
    padding: 4px 16px 16px;

    border: 1px solid var(--plate-border);
    border-radius: 10px;

    background: var(--item-background);

    .secondary {
      margin-bottom: 6px;
    }

    .transaction-detail {
      min-height: 76px;
      padding: 12px 0;

      border-bottom: 1px solid var(--item-border);

      .exchange-info {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
      }

      .subheader {
        inline-size: 100%;
        overflow-wrap: break-word;
      }
    }

    .last {
      padding-bottom: 0;

      border-bottom: none;
    }

    .total-amount-container {
      min-height: 76px;
      margin-top: 12px;
      padding: 12px 16px;

      border-radius: $border-radius;
      background-color: var(--grey-background);
    }
  }

  .p-button {
    width: 100%;
    height: 56px;
  }

  @include account-content(24px);
}

@media (max-width: $mobile-lg-breakpoint) {
  .crypto-send-dialog,
  .fiat-send-dialog {
    .p-dialog-header {
      display: none;
    }

    .p-dialog-content {
      padding: 15px !important;

      border-radius: $border-radius $border-radius 0 0;
    }
  }
}
