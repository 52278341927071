@font-face {
  font-family: Poppins-light;
  src: url('Poppins-Light.woff2') format('woff2'), url('Poppins-Light.woff') format('woff');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins-regular;
  src: url('Poppins-Regular.woff2') format('woff2'), url('Poppins-Regular.woff') format('woff');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins-medium;
  src: url('Poppins-Medium.woff2') format('woff2'), url('Poppins-Medium.woff') format('woff');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins-bold;
  src: url('Poppins-Bold.woff2') format('woff2'), url('Poppins-Bold.woff') format('woff');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins-semibold;
  src: url('Poppins-SemiBold.woff2') format('woff2'), url('Poppins-SemiBold.woff') format('woff');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: OpenSans-semibold;
  src: url('OpenSans-semibold.woff2') format('woff2'), url('OpenSans-semibold.woff') format('woff');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: OpenSans-regular;
  src: url('OpenSans-Regular.woff') format('woff');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: OpenSans-medium;
  src: url('OpenSans-Medium.woff') format('woff');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat-light;
  src: url('Montserrat-Light.woff2') format('woff2'), url('Montserrat-Light.woff') format('woff');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat-regular;
  src: url('Montserrat-Regular.woff2') format('woff2'), url('Montserrat-Regular.woff') format('woff');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat-medium;
  src: url('Montserrat-Medium.woff2') format('woff2'), url('Montserrat-Medium.woff') format('woff');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat-semibold;
  src: url('Montserrat-SemiBold.woff2') format('woff2'), url('Montserrat-SemiBold.woff') format('woff');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat-bold;
  src: url('Montserrat-Bold.woff2') format('woff2'), url('Montserrat-Bold.woff') format('woff');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Kanit-bold;
  src: url('Kanit-Bold.woff2') format('woff2'), url('Kanit-Bold.woff') format('woff');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Kanit-light;
  src: url('Kanit-Light.woff2') format('woff2'), url('Kanit-Light.woff') format('woff');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
