@import 'libs/wallex-core/src/styles/variables';

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: none !important;
}

.p-inputswitch {
  display: block !important;

  width: 40px;
  height: 28px;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--primary-text);
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: var(--primary-text);
}

.p-inputswitch .p-inputswitch-slider {
  background: var(--secondary-text);

  &::before {
    left: 4px;

    width: 16px;
    height: 16px;
    margin-top: -8px;
  }
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  &::before {
    transform: translateX(16px);
  }
}
