@import 'libs/wallex-core/src/styles/variables';
@import 'libs/wallex-core/src/styles/mixins';

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0;

  @include text(var(--font-base-regular), $font-size-small, 22px, 500);
}

.p-toast .p-toast-message .p-toast-message-content {
  gap: 0 6px;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0;
}

.toast-message {
  .p-toast {
    opacity: 1;

    .p-toast-message-content {
      align-items: center;
      gap: 0 10px;
    }

    .p-toast-message.p-toast-message-info {
      color: var(--text-green);
      border-width: 0;
      background: var(--light-green);
    }

    .p-toast-message.p-toast-message-info .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
      color: var(--text-green);
    }

    .p-toast-icon-close {
      color: var(--text-green) !important;
    }
  }

  &.waiting {
    .p-toast {
      .p-toast-message.p-toast-message-info {
        color: var(--light-blue);
        border-width: 0;
        background: var(--hint-background);
      }

      .p-toast-message.p-toast-message-info .p-toast-message-icon,
      .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
        color: var(--light-blue);
      }

      .p-toast-icon-close {
        color: var(--light-blue) !important;
      }
    }
  }
}

@media (max-width: $mobile-xmd-breakpoint) {
  .toast-message {
    .p-toast {
      width: 100% !important;
      max-width: 90%;

      .p-toast-message {
        .p-toast-message-content {
          padding: 10px;
          gap: 0 6px;
        }
      }
    }
  }
}
