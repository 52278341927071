@import 'libs/wallex-core/src/styles/mixins';
@import 'libs/wallex-core/src/styles/variables';

.asset-card,
.short-account-card,
.transaction-card,
.notification-card {
  display: flex;
  align-items: center;
  flex-direction: row;

  padding-right: 36px !important;

  cursor: pointer;

  .card-content-column {
    @include card-content(column, flex-start, 12px);
  }

  .card-content-row {
    @include card-content(row, center, 0);
  }

  @include card(var(--plate-border), 10px, 12px, 72px);
}

.coin-icon-container {
  width: var(--coin-icon-width, 40px);
  aspect-ratio: 1;

  padding: var(--coin-icon-padding, 8px);

  border-radius: 50%;

  .coin-icon {
    svg {
      width: var(--child-coin-icon-width, 24px);
      height: var(--child-coin-icon-width, 24px);
      aspect-ratio: 1;
    }

    @include flex(center, center);
  }

  &.small {
    width: var(--coin-icon-width, 36px);

    svg {
      width: var(--child-coin-icon-width, 21px);
      height: var(--child-coin-icon-width, 21px);
    }
  }

  &.large {
    width: var(--coin-icon-width, 48px);

    svg {
      width: var(--child-coin-icon-width, 32px);
      height: var(--child-coin-icon-width, 32px);
    }
  }

  @include flex(center, center);
}

.notification-card {
  justify-content: space-between;

  margin-bottom: 12px;
  padding: 12px 36px 12px 0;

  border: 1px solid var(--item-border);
  background: url('/assets/arrow-right.svg') no-repeat right 12px center;

  .card-content-row {
    min-height: 28px;
  }

  .card-content-column {
    justify-content: space-between;

    @include card-content(column, flex-start, 16px);
  }

  .is-card-read {
    display: block;

    width: 8px;
    height: 8px;
    margin-right: 8px;

    border: none;
    border-radius: 4px;
  }

  .unread {
    background-color: var(--light-blue);
  }
}

.simple-card {
  border: 1px solid var(--plate-border);
  border-radius: $border-radius;
  background: var(--item-background);
}
