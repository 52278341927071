@import 'libs/wallex-core/src/styles/variables';

@mixin overflow-trim {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin card($border-color, $border-radius, $padding, $height) {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  height: $height;
  padding: $padding;

  border: 1px solid $border-color;
  border-radius: $border-radius;
  background-color: var(--item-background);
}

@mixin card-button($background-image) {
  display: block;

  width: 24px;
  height: 24px;

  cursor: pointer;

  background-image: url($background-image);
}

@mixin card-content($flex-direction, $align-items: flex-start, $padding-left: 52px) {
  display: flex;
  align-items: $align-items;
  flex-direction: $flex-direction;

  min-height: 40px;
  padding-left: $padding-left;

  background-repeat: no-repeat;
  background-position: left center;
  background-size: 40px 40px;

  .card-content__title {
    width: 100%;
  }
}

@mixin text($font-base-value, $font-size-value, $line-height-value, $font-weight-value, $font-style: normal) {
  letter-spacing: normal;

  font-family: $font-base-value;
  font-size: $font-size-value;
  font-weight: $font-weight-value;
  font-style: $font-style;
  font-stretch: normal;
  line-height: $line-height-value;
}

@mixin radio($width, $height, $child-width, $child-height) {
  position: relative;

  width: $width;
  height: $height;

  cursor: pointer;

  border: 1px solid var(--item-border);
  border-radius: 50%;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    width: $child-width;
    height: $child-height;

    content: '';
    transform: translate(-50%, -50%);

    border-radius: 50%;
    background: transparent;
  }
}

@mixin flex($align-items, $justify-content) {
  display: flex;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin grid($columns, $gap) {
  display: grid;
  grid-template-columns: $columns;
  grid-gap: $gap;
}

@mixin empty-container() {
  height: calc(100vh - 144px);
  margin: -48px 0 0;
}

@mixin account-content($margin-bottom) {
  width: 63.33%;
  min-width: 300px;
  max-width: 760px;
  height: auto;
  margin: 24px auto $margin-bottom;

  @media screen and (max-width: 1200px) {
    width: 74%;
  }

  @media screen and (max-width: 1000px) {
    width: 80%;
  }

  @media screen and (max-width: 800px) {
    width: 84%;
  }
}

@mixin small-btn($padding: 0 16px) {
  height: fit-content;
  min-height: 32px;
  padding: $padding;

  border-radius: $border-radius-sm;

  @include text(var(--font-base-medium), $font-size-small, 22px, 500);
}

@mixin adaptive-size($property, $maxEndpoint, $minEndpoint, $minSize, $addSize) {
  #{$property}: calc($minSize + $addSize * ((100vw - #{$minEndpoint + px}) / $maxEndpoint - $minEndpoint));
}

@function hex-to-rgb($hex) {
  @return red($hex) green($hex) blue($hex);
}

@mixin flexible-width($percent, $fixed) {
  width: calc($percent - $fixed);
}

@mixin safari-scroll-bar {
  padding-bottom: 8px;

  background: linear-gradient(to bottom, var(--safari-scroll-background), var(--safari-scroll-background)) no-repeat;
  background-position: bottom left;
  background-size: 100% 8px;
}
