@import 'libs/wallex-core/src/styles/variables';
@import 'libs/wallex-core/src/styles/mixins';

.head {
  margin-bottom: 48px;

  .title {
    margin: 0;

    @include text(var(--font-base-regular), $title-size, 48px, 500);
  }
}

.auth-container {
  flex-direction: column;

  min-height: calc(100vh - 134px);
  padding: 0 20px 24px;

  &__col {
    width: 100%;
    max-width: 600px;
  }

  .submit-button {
    width: 100%;
    margin: 10px 0 0;
  }

  .p-button {
    width: 100%;
    height: 56px;
  }

  @include flex(center, space-between);
}

.login-link {
  &__text {
    color: var(--grey-text-icons);

    @include text(var(--font-base-regular), $font-size-regular, 24px, 400);
  }
}

@media (min-width: $desktop-breakpoint) {
  .auth-container {
    &__col {
      max-width: 700px;
    }
  }
}

@media (min-width: $desktop-breakpoint) {
  .auth-container {
    &__col {
      max-width: 700px;
    }
  }
}

@media (max-width: $mobile-breakpoint) {
  .login-link {
    &__text {
      display: none;
    }
  }

  .head {
    margin-bottom: 32px;
  }

  .auth-container {
    min-height: calc(100vh - 98px);
    padding: 0 15px 24px;
  }
}
