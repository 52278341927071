@import 'libs/wallex-core/src/styles/variables';
@import 'libs/wallex-core/src/styles/mixins';

a,
a:link {
  color: var(--button-primary);
}

.regular {
  @include text(var(--font-base-light), $font-size-regular, 24px, 300);
}

.large {
  @include text(var(--font-base-regular), $font-size-large, 28px, 400);
}

.text-primary {
  color: var(--main-text) !important;

  @include text(var(--font-base-regular), $font-size-regular, 22px, 400);
}

.primary {
  color: var(--main-text) !important;
}

a.primary {
  @include text(var(--font-base-regular), $font-size-regular, 22px, 500);
}

.subheader {
  @include text(var(--font-base-regular), $font-size-regular, 24px, 400);
}

.secondary {
  color: var(--secondary-text);
}

div.subheader {
  cursor: pointer;
}

.subheader2 {
  @include text(var(--font-base-regular), $font-size-regular, 24px, 500);
}

a.subheader2 {
  cursor: pointer;
}

.subheader-container {
  a {
    @include text(var(--font-base-regular), $font-size-small, 22px, 500);
  }
}

.p-component {
  font-family: var(--font-base-regular);
}

.title {
  @include text(var(--font-base-regular), $font-size-large, 28px, 400);
}

.body {
  @include text(var(--font-base-regular), $font-size-small, 22px, 400);
}

.body2 {
  @include text(var(--font-base-regular), $font-size-small, 22px, 500);
}

.body3 {
  @include text(var(--font-base-medium), $font-size-regular, 22px, 500);
}

.body-3 {
  @include text(var(--font-base-medium), $font-size-small, 22px, 600);
}

.title2 {
  margin-bottom: 16px;

  @include text(var(--font-base-medium), $font-size-large, 28px, 500);
}

.title3 {
  margin-bottom: 8px;

  @include text(var(--font-base-regular), $font-size-big, 30px, 400);
}

.headline2 {
  @include text(var(--font-base-regular), $font-size-semi-large, 40px, 500);
}

.caption {
  @include text(var(--font-base-regular), $font-size-xsmall, 20px, 400);
}

.f-300 {
  font-weight: 300;
}

.f-400 {
  font-weight: 400;
}

.f-500 {
  font-weight: 500;
}

.f-600 {
  font-weight: 600;
}
