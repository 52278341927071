@import '../../../../libs/wallex-core/src/styles/variables';
@import '../../../../libs/wallex-core/src/styles/mixins';

:root {
  --base-one: #fff;

  //backgrounds and borders
  --body-background: #fff;
  --item-border: #e2e3e7;
  --plate-border: #e2e3e7;
  --item-background: #fff;
  --item-disabled-background: #f6f6f6;
  --item-secondary-background: #efefef;
  --dropdown-search-background: #f8f9fa;
  --section-border: #e2e3e7;
  --main-text: #111314;
  --secondary-text: #64686f;
  --border-secondary: #f3f3f3;
  --scrollbar-color: rgb(17 19 20 / 40%);
  --separator-color: #979797;
  --widget-border: #055fc5;
  --safari-scroll-background: #fff;

  //intercom color
  --intercom-color: #055fc5;

  //menu text colors
  --menu-item: #111314;
  --menu-item-hover: #055fc5;
  --menu-icon: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7477%) hue-rotate(68deg) brightness(98%) contrast(107%);
  --menu-icon-hover: brightness(0) saturate(100%) invert(11%) sepia(86%) saturate(7298%) hue-rotate(204deg) brightness(102%) contrast(96%);

  //icons
  --icon-background: #055fc5;
  --icon-color: brightness(0) saturate(100%) invert(97%) sepia(0%) saturate(7487%) hue-rotate(126deg) brightness(111%) contrast(100%);
  --grey-text-icons: #767676;
  --icon-danger-background: #f9e6e6;
  --icon-danger-color: brightness(0) saturate(100%) invert(19%) sepia(95%) saturate(2326%) hue-rotate(346deg) brightness(97%) contrast(85%);
  --icon-inactive: #a7a7a7;
  --hover-filter: invert(20%) sepia(86%) saturate(6205%) hue-rotate(204deg) brightness(93%) contrast(96%);

  //wallet types
  --fiat-type: #177cef;
  --crypto-type: #055fc5;
  --saving-type: #082454;
  --market-chart: #1e55b2;

  //cards
  --card-text: #111314;
  --card-text-title: rgb(#{hex-to-rgb(#111314)} / 60%);
  --card-cvv: #111314;
  --logo-width: 12.8em;
  --fields-card: #e2e3e7;
  --card-logo: brightness(0) saturate(100%) invert(18%) sepia(100%) saturate(3166%) hue-rotate(203deg) brightness(92%) contrast(96%);
  --card-background: var(--primary-gradient);

  // app colors
  --hint-background: #edf3fc;
  --notification-background: #eef4ff;
  --gradient-color1: #8acbff;
  --gradient-color2: #fff;
  --main-red: #cc3030;
  --grey: #efefef;
  --grey-background: #f6f6f6;
  --grey-easy: #d9d9d9;
  --grey-primary: #888989;
  --light-red: #f9e6e6;
  --light-orange: #fbefe1;
  --dark-green: #205e3b;
  --text-green: #2aac66;
  --light-green: #e5f5ed;
  --light-blue: #1e55b2;
  --orange: #e17c05;
  --extra-light: #f7faff;
  --primary-text: #055fc5;
  --hover-primary: #0d89ec;
  --button-primary: #055fc5;
  --other-blue: #1e55b2;

  // gradients
  --primary-gradient: linear-gradient(136.32deg, var(--gradient-color1) 0%, var(--gradient-color2) 97.8%);

  // filters
  --to-light-blue: brightness(0) saturate(100%) invert(23%) sepia(81%) saturate(1452%) hue-rotate(202deg) brightness(100%) contrast(99%);
  --image-hover-filter: brightness(0) saturate(100%) invert(21%) sepia(89%) saturate(2352%) hue-rotate(212deg) brightness(87%) contrast(82%);
  --to-black: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7477%) hue-rotate(68deg) brightness(98%) contrast(107%);

  //sizes
  --auth-logo-height: 46px;
  --dashboard-logo-width: 146px;
  --dashboard-logo-mobile-width: 36.39px;

  //fonts
  --font-base-light: poppins-light;
  --font-base-regular: poppins-regular;
  --font-base-medium: poppins-medium;
  --font-base-bold: poppins-bold;
  --font-base-semiBold: poppins-semibold;
  --font-sans-regular: opensans-regular;
  --font-sans-semiBold: opensans-semibold;
  --font-sans-medium: opensans-medium;
  --font-kanit-light: kanit-light;
  --font-kanit-bold: kanit-bold;
}
