// ================================================================================================
// TYPOGRAPHY
// ================================================================================================

$font-size-regular: 16px; // default browser font
$font-size-small: 14px;
$font-size-ssmall: 13px;
$font-size-xsmall: 12px;
$font-size-xxsmall: 10px;
$font-size-large: 18px;
$font-size-big: 20px;
$font-size-xbig: 22px;
$font-size-xlarge: 24px;
$font-size-semi-large: 26px;
$font-size-xxlarge: 32px;
$font-size-xxxlarge: 70px;
$font-size-form: 15px;
$font-size-password: 28px;
$title-size: $font-size-xxlarge;

// ================================================================================================
// SPACING
// ================================================================================================

// These vars match Bootstrap's spacing utility classes
$spacer-1: 0.25rem;
$spacer-2: 0.5rem;
$spacer-3: 1rem;
$spacer-4: 1.5rem;
$spacer-5: 3rem;

// parameters
$max-input-width: 232px;
$border-radius: 10px;
$border-radius-small: 8px;
$border-radius-sm: 6px;
$header-height: 72px;
$content-padding: 24px;
$small-content-padding: 16px;
$aside-width: 240px;
$line-height: 22px;
$input-height: 56px;
$max-input-width: 564px;
$container-max-with: 760px;
$desktop-breakpoint: 1470px;
$desktop-first-breakpoint: 1386px;
$desktop-sm-first-breakpoint: 1300px;
$desktop-sm-breakpoint: 1250px;
$desktop-sm-second-breakpoint: 1170px;
$table-lg-breakpoint: 1150px;
$table-md-breakpoint: 1050px;
$table-xmd-breakpoint: 991px;
$table-breakpoint: 955px;
$table-sm-breakpoint: 900px;
$mobile-lg-breakpoint: 750px;
$mobile-breakpoint: 650px;
$mobile-small-breakpoint: 600px;
$mobile-md-breakpoint: 550px;
$mobile-xmd-breakpoint: 500px;
$intercom-full-width-breakpoint: 427px;
$mobile-sm-breakpoint: 400px;
$mobile-smm-breakpoint: 330px;
