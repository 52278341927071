.m-l-r-10 {
  margin-right: 10px;
  margin-left: 10px;
}

.p-0 {
  padding: 0;
}

.p-l-0 {
  padding-left: 0;
}

.p-r-0 {
  padding-right: 0;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.m-r-20 {
  margin-right: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-l-8 {
  margin-left: 8px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.float-l {
  float: left;
}

.float-r {
  float: right;
}

.float-clear {
  clear: both;
}

.width-auto {
  width: auto;
}

.width-100 {
  width: 100%;
}

.width-20 {
  width: 20%;
}

.width-40 {
  width: 40%;
}

.width-45 {
  width: 45%;
}

.width-24 {
  width: 24%;
}

.width-15 {
  width: 15%;
}

.width-10 {
  width: 10%;
}

.width-8 {
  width: 8%;
}

.width-5 {
  width: 5%;
}

.width-100-px {
  width: 110px;
}

.disp-in-block {
  display: inline-block;
}

.disp-grid {
  display: grid;
}

.disp-block {
  display: block;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.field-height {
  height: 36px;
}

.font-bold {
  font-weight: bold;
}

.clear-float {
  clear: none;
}

.cursor-def {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.mb-32 {
  margin: 0 0 32px;
}

.mt-32 {
  margin: 32px 0 0;
}

.--bg-grey {
  background: var(--grey);
}
