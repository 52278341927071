@import 'libs/wallex-core/src/styles/variables';

.dashboard {
  .p-component {
    font-family: var(--font-base-regular);
  }

  .accounts,
  .all-accounts {
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
      margin: 0 24px 0 0;
      padding: 0 0 12px;

      border: none;

      @include text(var(--font-base-regular), $font-size-small, 22px, 400);
    }

    .p-tabmenu-nav .p-menuitem-text {
      line-height: 22px;
    }

    .p-tabmenu .p-tabmenu-nav {
      border-width: 0 0 1px;
      border-color: var(--item-border);
      background: transparent;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
      color: var(--primary-text);
      border-color: var(--primary-text);
      border-bottom: 2px solid;
      background: transparent;
    }
  }

  .all-accounts {
    .p-inputtext {
      padding: 7px 12px 7px 44px;
    }
  }

  @include text(var(--font-base-regular), $font-size-small, 22px, 400);
}
